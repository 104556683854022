/**
 * Найти в строке текстовые ссылки и обернуть их в теги <a>
 * @param str {string} - исходная строка
 * @param linkTarget {string} - аттрибут ссылки target, default - '_blank'
 * @param linkClassName {string} - css класс(ы) ссылки, default - 'esmp-link'
 * @return {string} - преобразованная строка со ссылками
 */

export default (str, linkTarget = '_blank', linkClassName = 'esmp-link') => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const wrapLink = (url, target, className) => `<a href="${url}" target="${target}" class="${className}">${url}</a>`;
  return str.replace(urlRegex, (url) => wrapLink(url, linkTarget, linkClassName));
};
