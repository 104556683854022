<template>
  <esmp-modal
    v-model="show"
    title="Регистрация в чат-боте"
    class-name="modal-chat-bot-registration"
  >
    <form
      @submit="onSubmit"
      ref="form"
    >
      <hm-form
        v-if="model"
        ref="myform"
        :fields="checklist"
        v-model="model"
      />
      <div v-if="errorMessage" class="modal-chat-bot-registration__error">
        {{ errorMessage }}
      </div>
    </form>
    <template v-if="staticFields.length">
      <div
        class="modal-chat-bot-registration__static-field"
        v-for="field in staticFields"
        :key="field.id"
      >
        <span v-if="field.class === 'text'" v-html="wrapUrlAsInText(field.value)" />
        <img
          v-if="field.class === 'image'"
          :src="`data:image/png;base64,${field.value}`"
        >
      </div>
    </template>
    <div slot="footer">
      <esmp-button @click="onSubmit">
        Зарегистрироваться
      </esmp-button>
    </div>
  </esmp-modal>
</template>

<script>
import { mapActions } from 'vuex';
import HmForm from '@/components/hm-form/';
import createDefaultModel from '@/components/hm-form/helpers/createDefaultModel';
import wrapUrlAsInText from '@/utils/wrapUrlAsInText';

export default {
  name: 'ModalChatBotRegistration',
  model: {
    prop: 'isShow',
    event: 'change',
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HmForm,
  },
  data() {
    return {
      model: undefined,
      checklist: [],
      staticFields: [],
      errorMessage: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    wrapUrlAsInText,
    async getControls() {
      this.setLoading({ key: 'app', value: true });

      await this.$API.employeeInfo
        .getAll()
        .then(({ data }) => {
          const controls = Object.freeze(data || []);
          this.checklist = controls
            .map((i) => ({ ...i, required: true }))
            .filter((field) => !['image', 'text'].includes(field.class));
          this.model = createDefaultModel(this.checklist);
          this.staticFields = controls.filter((field) => ['image', 'text'].includes(field.class));
        });

      this.setLoading({ key: 'app', value: false });
    },
    // eslint-disable-next-line consistent-return
    async onSubmit(e) {
      e.preventDefault();
      let valid = false;
      await this.$refs.myform.validate().then((success) => {
        valid = success;
      });
      if (valid) {
        const data = [];
        Object.keys(this.model).forEach((id) => {
          const { techName } = this.checklist.find((item) => item.id === Number(id));
          data.push({ techName, value: this.model[id] });
        });
        return this.$API.employeeInfo
          .updateAll(data)
          .then(() => {
            this.$EsmpNotify.$show('Регистрация прошла успешно', 'success');

            this.show = false;
          })
          .catch((error) => {
            this.errorMessage = error?.response?.data?.errorMessage || 'Неизвестная ошибка';
          });
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.getControls();
      }
    },
    model() {
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="scss">
.modal-chat-bot-registration {
  .esmp-modal {
    width: 800px !important;
    @include for-size(phone-landscape-down) {
      width: 700px !important;
    }
    @include for-size(phone-portrait-down) {
      width: auto !important;
    }
  }
  &__error {
    margin-top: 20px;
    color: $error-color;
    font-size: 13px;
  }
}
.esmp-modal-body {
  font-size: 16px;
}
</style>
